<template>
  <v-container id="sendConfirmation">
    <!-- page header -->
    <v-row>
      <v-col cols="12" class="d-flex">
        <h1 class="headline font-weight-medium">
          {{ editMode ? "Edit Confirmation" : "Send Confirmation" }}
        </h1>
        <v-spacer />
        <v-btn
          color="error"
          outlined
          class="mr-12"
          @click="cancelDialog = true"
          v-if="booking && userAccount"
          >Cancel Booking</v-btn
        >
        <v-switch
          dense
          v-model="editMode"
          label="Edit This Confirmation"
          class="mt-1 mr-10"
        ></v-switch>
        <v-btn color="primary" depressed to="/bookings">
          Back to Bookings</v-btn
        >
      </v-col>
      <!-- edit mode info -->
      <v-fade-transition>
        <v-col cols="12" v-if="editMode"
          ><v-alert dense outlined text type="info" :icon="false" class="pt-5">
            <div>
              <h3 class="mb-3">
                Editing this confirmation will update the information displayed
                below.
              </h3>
              <p>
                Use this feature to edit dates, surcharges, resort info, etc.
              </p>
              <p>
                If a secondary email is added/updated, the confirmation will be
                sent to it as usual.
              </p>
              <p>
                <strong
                  >Editing this confirmation will not automatically email it to
                  the traveler.</strong
                >
                You will still need to send the confirmation as usual after
                you've made edits.
              </p>
            </div>
          </v-alert></v-col
        >
      </v-fade-transition>
    </v-row>

    <!-- click here to view all available booking information - expansion panel -->
    <v-row>
      <v-col cols="12">
        <v-sheet outlined rounded>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header
                :class="$vuetify.theme.dark ? '' : 'grey lighten-3'"
              >
                Click here to view all available booking information
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-if="userAccount">
                  <!-- userAccount info -->
                  <v-col cols="12" md="6">
                    <v-list>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Name:
                            {{
                              userAccount.firstName + " " + userAccount.lastName
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Phone:
                            {{
                              formatPhone(userAccount.phone)
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Email: {{ userAccount.email }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Secondary Email:
                            {{ userAccount.secondaryEmail }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Package Code:
                            {{ booking.packageCode }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Package ID:
                            {{ userAccount.packageID }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Event Report ID:
                            {{ userAccount.eventReportID }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <!-- userAccount info -->
                  <!-- booking info -->
                  <v-col cols="12" md="6">
                    <v-list>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="text-capitalize"
                            >Destination:
                            {{ booking.destination }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Check In Date:
                            {{
                              formatDate(booking.checkInDate)
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Check Out Date:
                            {{
                              formatDate(booking.checkOutDate)
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Resort Name:
                            {{ booking.resortName }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Resort Address:
                            {{ booking.resortAddress }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Number of Nights:
                            {{ booking.numberOfNights }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Bedrooms: {{ booking.bedrooms }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Max Occupancy:
                            {{ booking.maxOccupancy }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Kitchen: {{ booking.kitchen }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Total Cost: ${{
                              booking.totalCost
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="py-1">
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            >Date Selected By Traveler:
                            {{
                              formatDate(booking.dateSelectedForBooking)
                            }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        class="py-1"
                        :class="[
                          booking.isConfirmed ? 'green' : '',
                          !booking.isConfirmed ? 'red' : '',
                          booking.archived ? 'grey' : '',
                        ]"
                      >
                        <v-list-item-content class="py-0">
                          <v-list-item-title
                            style="word-wrap: break-word; white-space: normal"
                          >
                            <span v-if="booking.archived == true"
                              >This booking has been archived and the traveler
                              has not been sent a confirmation</span
                            >
                            <span v-else-if="booking.isConfirmed == true"
                              >This traveler has already been sent a
                              confirmation</span
                            >
                            <span v-else-if="booking.isConfirmed == false"
                              >This traveler has not been sent a
                              confirmation</span
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <!-- booking info -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-col>
    </v-row>

    <!-- form -->
    <v-row>
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary pt-8">
            <v-form ref="form" @submit.prevent="onSubmit">
              <v-row>
                <!-- firstName -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="First Name *"
                    v-model.trim="booking.firstName"
                    :rules="[textRules.required]"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading"
                    dense
                    hint="First name on confirmation. Will NOT change first name in user account if changed here."
                  ></v-text-field>
                </v-col>
                <!-- firstName -->
                <!-- lastName -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Last Name *"
                    dense
                    v-model.trim="booking.lastName"
                    :rules="[textRules.required]"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading"
                    hint="Last name on confirmation. Will NOT change last name in user account if changed here."
                  ></v-text-field>
                </v-col>
                <!-- lastName -->
                <!-- email -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model.trim="booking.email"
                    @input="userAccount.email = userAccount.email.toLowerCase()"
                    label="Email *"
                    type="email"
                    dense
                    :rules="[textRules.required, emailRules.valid]"
                    :loading="loading"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <!-- email -->
                <!-- secondaryEmail -->
                <v-col cols="12" md="6" lg="4" v-if="userAccount">
                  <v-text-field
                    v-model.trim="userAccount.secondaryEmail"
                    @input="
                      userAccount.secondaryEmail = userAccount.secondaryEmail.toLowerCase()
                    "
                    label="Secondary Email *"
                    type="email"
                    dense
                    :rules="[emailRules.secondary]"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- secondaryEmail -->
                <!-- phone -->

                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-if="userAccount && userAccount.phone"
                    :value="
                      userAccount && userAccount.phone
                        ? formatPhone(userAccount.phone)
                        : null
                    "
                    label="Phone *"
                    type="text"
                    dense
                    :loading="loading"
                    :disabled="true"
                  ></v-text-field>
                </v-col>
                <!-- phone -->
                <!-- destination -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Destination *"
                    dense
                    v-model.trim="booking.destination"
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- destination -->
                <!-- resortName -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Resort Name *"
                    v-model.trim="booking.resortName"
                    dense
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- resortName -->
                <!-- resortAddress -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Resort Address *"
                    v-model.trim="booking.resortAddress"
                    :rules="[textRules.required]"
                    dense
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- resortAddress -->
                <!-- checkInDate -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Check In Date *"
                    :value="formatDate(checkInDate)"
                    dense
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                    :readonly="true"
                    @click="showCheckInDatePicker = !showCheckInDatePicker"
                  ></v-text-field>
                  <v-expand-transition>
                    <div
                      v-if="showCheckInDatePicker"
                      class="pa-6 grey lighten-3"
                      style="border-radius: 4px"
                    >
                      <v-date-picker
                        v-model="checkInDate"
                        full-width
                        @change="showCheckInDatePicker = false"
                      ></v-date-picker>
                    </div>
                  </v-expand-transition>
                </v-col>
                <!-- checkInDate -->
                <!-- checkOutDate -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Check Out Date *"
                    dense
                    :value="formatDate(checkOutDate)"
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                    :readonly="true"
                    @click="showCheckOutDatePicker = !showCheckOutDatePicker"
                  ></v-text-field>
                  <v-expand-transition>
                    <div
                      v-if="showCheckOutDatePicker"
                      class="pa-6 grey lighten-3"
                      style="border-radius: 4px"
                    >
                      <v-date-picker
                        v-model="checkOutDate"
                        full-width
                        @change="showCheckOutDatePicker = false"
                      ></v-date-picker>
                    </div>
                  </v-expand-transition>
                </v-col>
                <!-- checkOutDate -->
                <!-- bedrooms -->
                <v-col cols="12" md="6" lg="4" id="bedrooms">
                  <v-text-field
                    label="Bedrooms *"
                    v-model.trim="booking.bedrooms"
                    dense
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- maxOccupancy -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Max Occupancy *"
                    v-model.trim="booking.maxOccupancy"
                    dense
                    :rules="[textRules.required]"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- kitchen -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Kitchen *"
                    v-model.trim="booking.kitchen"
                    :rules="[textRules.required]"
                    dense
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- kitchen -->
                <!-- source -->
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    label="Source *"
                    v-model.trim="booking.source"
                    :rules="[textRules.required]"
                    dense
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <!-- source -->
                <!-- Party Size -->
                <v-col cols="12" md="6" lg="8">
                  <v-row dense>
                    <v-col cols="12">
                      <p class="font-weight-medium mb-6">
                        <v-icon small left class="mb-1"
                          >mdi-information-outline</v-icon
                        >
                        Party Size Information
                      </p>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Number of Adults *"
                        v-model.trim="booking.numAdults"
                        :rules="[textRules.required]"
                        dense
                        :loading="loading"
                        :disabled="loading"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Number of Children *"
                        v-model.trim="booking.numChildren"
                        :rules="[textRules.required]"
                        dense
                        :loading="loading"
                        :disabled="loading"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                        label="Handicap Required"
                        v-model="booking.handicapRequired"
                        dense
                        :loading="loading"
                        :disabled="loading"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- Party Size -->
              </v-row>

              <v-row class="mb-6">
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-toolbar-title class="mb-2">
                    <strong>Expected cost</strong>
                    <span class="ml-6"> </span>
                  </v-toolbar-title>
                </v-col>
              </v-row>

              <v-row>
                <!-- peakSeasonSurcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Peak Season Surcharge"
                    v-model.trim="booking.peakSeasonSurchargeAmount"
                    dense
                    :loading="loading"
                    
                    :disabled="loading"
                    @input="calcValues"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="peakSeasonSurchargeAmount"
                      placeholder="Peak Season Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.peakSeasonSurchargeAmount"
                      :loading="loading"
                    />
                    <label for="peakSeasonSurchargeAmount" class="form__label"
                      >Peak Season Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- peakSeasonSurcharge -->
                <!-- highOccupancySurcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="High Occupancy Surcharge"
                    v-model.trim="booking.highOccupancySurchargeAmount"
                    dense
                    
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="highOccupancySurchargeAmount"
                      placeholder="High Occupancy Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.highOccupancySurchargeAmount"
                      :loading="loading"
                    />
                    <label
                      for="highOccupancySurchargeAmount"
                      class="form__label"
                      >High Occupancy Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- highOccupancySurcharge -->
                <!-- totalSurcharges -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Total Surcharges"
                    :value="booking.totalSurcharges"
                    :loading="loading"
                    
                    dense
                    :disabled="loading"
                    readonly
                    hint="this field is read only"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="totalSurcharges"
                      placeholder="Total Surcharges"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.totalSurcharges"
                      :loading="loading"
                      readonly
                      hint="this field is read only"
                    />
                    <label for="totalSurcharges" class="form__label"
                      >Total Surcharges</label
                    >
                  </div>
                </v-col>
                <!-- totalSurcharges -->
                <!-- toursTickets -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Tours &amp; Tickets"
                    v-model.trim="booking.toursTickets"
                    :loading="loading"
                    
                    dense
                    :disabled="loading"
                    :readonly="loading"
                    @input="calcValues"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="toursTickets"
                      placeholder="Tours &amp; Tickets"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.toursTickets"
                      :loading="loading"
                    />
                    <label for="toursTickets" class="form__label"
                      >Tours &amp; Tickets</label
                    >
                  </div>
                </v-col>
                <!-- totalSurcharges -->
                <!-- costToOrg -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Cost to Org"
                    v-model="booking.costToOrg"
                    
                    :loading="loading"
                    @input="calcValues"
                    :disabled="loading"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="costToOrg"
                      placeholder="Cost to Org"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.costToOrg"
                      :loading="loading"
                    />
                    <label for="costToOrg" class="form__label"
                      >Cost to Org</label
                    >
                  </div>
                </v-col>
                <!-- costToOrg -->
                <!-- totalIncome -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Total Incone"
                    :value="booking.totalIncome"
                    
                    :loading="loading"
                    readonly
                    disabled
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="totalIncome"
                      placeholder="Total Incone"
                      disabled
                      @input="calcValues"
                      v-model.trim="booking.totalIncome"
                      :loading="loading"
                    />
                    <label for="totalIncome" class="form__label"
                      >Total Incone</label
                    >
                  </div>
                </v-col>
                <!-- totalIncome -->
                <!-- cost from supplier -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Room Cost from Supplier"
                    v-model.trim="booking.roomCost"
                    
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="roomCost"
                      placeholder="Room Cost from Supplier"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.roomCost"
                      :loading="loading"
                    />
                    <label for="roomCost" class="form__label"
                      >Room Cost from Supplier</label
                    >
                  </div>
                </v-col>
                <!-- cost from supplier -->
                <!-- taxesAndFees -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Taxes and Fees"
                    v-model.trim="booking.taxesAndFees"
                    
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="taxesAndFees"
                      placeholder="Taxes and Fees"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.taxesAndFees"
                      :loading="loading"
                    />
                    <label for="taxesAndFees" class="form__label"
                      >Taxes and Fees</label
                    >
                  </div>
                </v-col>
                <!-- taxesAndFees -->
                <!-- commissionsPaid -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Commissions Paid"
                    v-model.trim="booking.commissionsPaid"
                    :loading="loading"
                    
                    :disabled="loading"
                    @input="calcValues"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="commissionsPaid"
                      placeholder="Commissions Paid"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.commissionsPaid"
                      :loading="loading"
                    />
                    <label for="commissionsPaid" class="form__label"
                      >Commissions Paid</label
                    >
                  </div>
                </v-col>

                <!-- 3rd year surcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Third Year Surcharge"
                    v-model.trim="booking.thirdYearSurcharge"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="thirdYearSurcharge"
                      placeholder="Third Year Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="booking.thirdYearSurcharge"
                      :loading="loading"
                    />
                    <label for="thirdYearSurcharge" class="form__label"
                      >Third Year Surcharge</label
                    >
                  </div>
                </v-col>

                <!-- totalCost -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Total Cost"
                    :value="booking.totalCost"
                    :loading="loading"
                    
                    disabled
                    readonly
                    @input="calcValues"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="totalCost"
                      placeholder="Total Cost"
                      disabled
                      @input="calcValues"
                      v-model.trim="booking.totalCost"
                      :loading="loading"
                    />
                    <label for="totalCost" class="form__label"
                      >Total Cost</label
                    >
                  </div>
                </v-col>
                <!-- totalCost -->
                <!-- net -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Net Profit"
                    :value="booking.netProfit"
                    :loading="loading"
                    disabled
                    readonly
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="netProfit"
                      placeholder="Net Profit"
                      disabled
                      @input="calcValues"
                      v-model.trim="booking.netProfit"
                      :loading="loading"
                    />
                    <label for="netProfit" class="form__label"
                      >Net Profit</label
                    >
                  </div>
                </v-col>
                <!-- net -->
              </v-row>

              <v-row class="mb-6">
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>

              <!-- Actual cost -->

              <v-row>
                <v-col cols="6">
                  <v-toolbar-title class="mb-2">
                    <strong>Actual cost</strong>
                    <span class="ml-6"> </span>
                  </v-toolbar-title>
                </v-col>
              </v-row>

              <v-row>
                <!-- Package Cost -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Package Cost"
                    :value="actualBooking.actualPackageCost"
                    :loading="loading"
                    readonly
                    disabled
                    @input="calcValues"
                    dense
                    
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="actualPackageCost"
                      placeholder="Package Cost"
                      disabled
                      @input="calcValues"
                      v-model.trim="actualBooking.actualPackageCost"
                      :loading="loading"
                    />
                    <label for="actualPackageCost" class="form__label"
                      >Package Cost</label
                    >
                  </div>
                </v-col>
                <!-- Package Cost -->
                <!-- commissionsPaid -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Commissions Paid"
                    v-model.trim="actualBooking.actualCommissionsPaid"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    dense
                    
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualCommissionsPaid"
                      placeholder="Commissions Paid"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualCommissionsPaid"
                      :loading="loading"
                    />
                    <label for="actualCommissionsPaid" class="form__label"
                      >Commissions Paid</label
                    >
                  </div>
                </v-col>
                <!-- Commissions Paid -->
                <!-- peakSeasonSurcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Peak Season Surcharge"
                    v-model.trim="actualBooking.actualPeakSeasonSurchargeAmount"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualPeakSeasonSurchargeAmount"
                      placeholder="Peak Season Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="
                        actualBooking.actualPeakSeasonSurchargeAmount
                      "
                      :loading="loading"
                    />
                    <label
                      for="actualPeakSeasonSurchargeAmount"
                      class="form__label"
                      >Peak Season Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- peakSeasonSurcharge -->
                <!-- highOccupancySurcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="High Occupancy Surcharge"
                    v-model.trim="
                      actualBooking.actualHighOccupancySurchargeAmount
                    "
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualHighOccupancySurchargeAmount"
                      placeholder="High Occupancy Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="
                        actualBooking.actualHighOccupancySurchargeAmount
                      "
                      :loading="loading"
                    />
                    <label
                      for="actualHighOccupancySurchargeAmount"
                      class="form__label"
                      >High Occupancy Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- highOccupancySurcharge -->
                <!-- Upgrade Surcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Upgrade Surcharge"
                    v-model.trim="actualBooking.actualUpgradeSurcharge"
                    :loading="loading"
                    :disabled="loading"
                    :readonly="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualUpgradeSurcharge"
                      placeholder="Upgrade Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualUpgradeSurcharge"
                      :loading="loading"
                    />
                    <label for="actualUpgradeSurcharge" class="form__label"
                      >Upgrade Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- Upgrade Surcharge -->
                <!-- 3rd year surcharge -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Third Year Surcharge"
                    v-model.trim="actualBooking.actualThirdYearSurcharge"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualThirdYearSurcharge"
                      placeholder="Third Year Surcharge"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualThirdYearSurcharge"
                      :loading="loading"
                    />
                    <label for="actualThirdYearSurcharge" class="form__label"
                      >Third Year Surcharge</label
                    >
                  </div>
                </v-col>
                <!-- 3rd year surcharge -->
                <!-- Other Fees -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Other Fees"
                    v-model.trim="actualBooking.actualOtherFees"
                    :loading="loading"
                    @input="calcValues"
                    :disabled="loading"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualOtherFees"
                      placeholder="Other Fees"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualOtherFees"
                      :loading="loading"
                    />
                    <label for="actualOtherFees" class="form__label"
                      >Other Fees</label
                    >
                  </div>
                </v-col>
                <!-- Other Fees -->
                <!-- Extras Cost -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Extras Cost"
                    v-model.trim="actualBooking.actualExtrasCost"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualExtrasCost"
                      placeholder="Extras Cost"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualExtrasCost"
                      :loading="loading"
                    />
                    <label for="actualExtrasCost" class="form__label"
                      >Extras Cost</label
                    >
                  </div>
                </v-col>
                <!-- Extras Cost -->
                <!-- cost from supplier -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Room Cost"
                    v-model.trim="actualBooking.actualRoomCost"
                    :loading="loading"
                    :disabled="loading"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualRoomCost"
                      placeholder="Room Cost"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualRoomCost"
                      :loading="loading"
                    />
                    <label for="actualRoomCost" class="form__label"
                      >Room Cost</label
                    >
                  </div>
                </v-col>
                <!-- cost from supplier -->
                <!-- taxesAndFees -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Taxes and Fees"
                    v-model.trim="actualBooking.actualTaxesAndFees"
                    :loading="loading"
                    :disabled="loading"
                    maxlength="5"
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualTaxesAndFees"
                      placeholder="Taxes and Fees"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualTaxesAndFees"
                      :loading="loading"
                    />
                    <label for="actualTaxesAndFees" class="form__label"
                      >Taxes and Fees</label
                    >
                  </div>
                </v-col>
                <!-- taxesAndFees -->
                <!-- totalCost -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="Total Cost"
                    :value="actualBooking.actualTotalCost"
                    :loading="loading"
                    disabled
                    readonly
                    @input="calcValues"
                    
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="actualTotalCost"
                      placeholder="Total Cost"
                      disabled
                      readonly
                      hint="this field is read only"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualTotalCost"
                      :loading="loading"
                    />
                    <label for="actualTotalCost" class="form__label"
                      >Total Cost</label
                    >
                  </div>
                </v-col>
                <!-- totalCost -->
                <!-- p/l -->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <!-- <v-text-field
                    label="P/L"
                    :value="actualBooking.actualProfitAndLost"
                    
                    :loading="loading"
                    disabled
                    readonly
                    hint="this field is read only"
                    dense
                    type="number"
                  ></v-text-field> -->
                  <div class="form__group field event-border-dash">
                    <input
                      type="number"
                      class="form__field"
                      name="actualProfitAndLost"
                      placeholder="P/L"
                      disabled
                      hint="this field is read only"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualProfitAndLost"
                      :loading="loading"
                    />
                    <label for="actualProfitAndLost" class="form__label"
                      >P/L</label
                    >
                  </div>
                </v-col>
                <!-- p/l -->

                <!-- Repeat P/L-->
                <!-- <v-col cols="12" md="6" lg="3" class="mt-4">
                  <v-text-field
                    label="Repeat P/L"
                    v-model.trim="actualBooking.actualRepeatProfitAndLost"
                    :loading="loading"
                    
                    :disabled="loading"
                    @input="calcValues"
                    dense
                    type="number"
                  ></v-text-field>
                </v-col> -->
                <!-- Repeat P/L -->

                <!-- Repeat P/L-->
                <v-col cols="12" md="6" lg="3" class="mt-4">
                  <div class="form__group field">
                    <input
                      type="number"
                      class="form__field"
                      name="actualRepeatProfitAndLost"
                      placeholder="Repeat P/L"
                      :disabled="loading"
                      @input="calcValues"
                      v-model.trim="actualBooking.actualRepeatProfitAndLost"
                      :loading="loading"
                    />
                    <label for="actualRepeatProfitAndLost" class="form__label"
                      >Repeat P/L</label
                    >
                  </div>
                </v-col>
                <!-- Repeat P/L -->
              </v-row>

              <v-row class="mb-6">
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>

              <!-- Actual cost -->

              <v-row>
                <!-- admin notes -->
                <v-col cols="12" md="6">
                  <v-textarea
                    :loading="loading"
                    :disabled="loading"
                    outlined
                    v-model="booking.adminNotes"
                    label="Admin Notes"
                    persistent-hint
                    hint="Admin notes here. Traveler will NOT see."
                  ></v-textarea
                ></v-col>

                <!-- admin notes -->
                <!-- traveler notes section -->
                <v-col cols="12" md="12">
                  <v-card class="pa-4">
                    <v-toolbar-title class="mb-2">
                      <strong>Traveler Notes</strong>
                    </v-toolbar-title>
                    <v-row>
                      <!--<v-col cols="12" md="6">
                         <v-textarea
                          outlined
                          :loading="loading"
                          :disabled="loading"
                          v-model="booking.travelerNotes"
                          label="Traveler Notes/Instructions"
                          persistent-hint
                          hint="Traveler notes/instructions here. Traveler WILL see."
                        ></v-textarea>
                      </v-col> -->
                      <v-col cols="12" md="6">
                        <quill-editor
                          v-model="booking.travelerNotes"
                          class="custom-quill-editor"
                        ></quill-editor>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              outlined
                              label="Check-In Date/Time"
                              v-model="booking.checkInDateTime"
                              type="datetime-local"
                              :disabled="loading"
                              :step="900"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              outlined
                              label="Check-Out Date/Time"
                              v-model="booking.checkOutDateTime"
                              type="datetime-local"
                              :disabled="loading"
                              :step="900"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              outlined
                              label="Resort Phone Number"
                              v-model="booking.resortPhoneNumber"
                              type="tel"
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-select
                              outlined
                              label="Max Occupancy in Details"
                              v-model="booking.maxOccupancyDetails"
                              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                              :disabled="loading"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- traveler notes section -->

                <!-- confirmationNumber -->
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Confirmation # *"
                    v-model.trim="booking.confirmationNumber"
                    :loading="loading"
                    :disabled="loading"
                    dense
                    outlined
                    :rules="editMode ? [textRules.required] : []"
                  ></v-text-field>
                </v-col>
                <!-- confirmationNumber -->
                <v-col cols="12" md="6" v-if="booking.isConfirmed">
                  <v-alert
                    :type="booking.isConfirmed ? 'success' : 'error'"
                    icon="mdi-check-circle-outline"
                    outlined
                    text
                  >
                    {{
                      booking.isConfirmed
                        ? "This traveler has already been sent a confirmation on"
                        : "This traveler has not been sent a confirmation"
                    }}
                    <br />{{
                      booking.isConfirmed
                        ? formatDate(booking.dateConfirmed)
                        : ""
                    }}
                  </v-alert>
                </v-col>
                <!-- confirmationNumber -->
              </v-row>
            </v-form>
            <!-- document -->
            <v-col cols="12" md="6">
              <!-- <v-card> -->
              <v-toolbar-title class="mb-2">
                <strong>Check In Documents</strong>
              </v-toolbar-title>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    :style="{ width: `100%` }"
                    :disabled="!!emailDoc || loading"
                    @click="$refs.fileInput1.click()"
                    :loading="loading"
                  >
                    Upload Document 1
                    <input
                      ref="fileInput1"
                      type="file"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx"
                      style="display: none"
                      @change="onFileSelected($event, 1)"
                    />
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <span class="document-certificate">
                    <a
                      :style="{ width: `100%` }"
                      color="teal lighten-2"
                      :loading="loading"
                      :disabled="loading"
                      depressed
                      v-if="emailDoc"
                      class="openDocLink"
                      @click="openCertificate(1)"
                      ><p class="mb-0 overline text-capitalize">
                        {{ emailDoc.name.substring(0, 16) }}...
                      </p>
                    </a>
                    <v-icon v-if="emailDoc" @click="clear(1)">mdi-close</v-icon>
                  </span>
                </v-col>

                <v-col cols="12" md="6" v-if="emailDoc || emailDoc2">
                  <v-btn
                    color="primary"
                    :style="{ width: `100%` }"
                    :disabled="!!emailDoc2 || loading"
                    @click="$refs.fileInput2.click()"
                    :loading="loading"
                  >
                    Upload Document 2
                    <input
                      ref="fileInput2"
                      type="file"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx"
                      style="display: none"
                      @change="onFileSelected($event, 2)"
                    />
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" v-if="emailDoc || emailDoc2">
                  <span class="document-certificate">
                    <a
                      :style="{ width: `100%` }"
                      color="teal lighten-2"
                      :loading="loading"
                      :disabled="loading"
                      depressed
                      v-if="emailDoc2"
                      class="openDocLink"
                      @click="openCertificate(2)"
                      ><p class="mb-0 overline text-capitalize">
                        {{ emailDoc2.name.substring(0, 16) }}...
                      </p>
                    </a>
                    <v-icon v-if="emailDoc2" @click="clear(2)"
                      >mdi-close</v-icon
                    >
                  </span>
                </v-col>

                <v-col cols="12" md="6" v-if="emailDoc2 || emailDoc3">
                  <v-btn
                    color="primary"
                    :style="{ width: `100%` }"
                    :disabled="!!emailDoc3 || loading"
                    @click="$refs.fileInput3.click()"
                    :loading="loading"
                  >
                    Upload Document 3
                    <input
                      ref="fileInput3"
                      type="file"
                      accept=".pdf,.png,.jpeg,.jpg,.doc,.docx"
                      style="display: none"
                      @change="onFileSelected($event, 3)"
                    />
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6" v-if="emailDoc2 || emailDoc3">
                  <span class="document-certificate">
                    <a
                      :style="{ width: `100%` }"
                      color="teal lighten-2"
                      :loading="loading"
                      :disabled="loading"
                      depressed
                      v-if="emailDoc3"
                      class="openDocLink"
                      @click="openCertificate(3)"
                      ><p class="mb-0 overline text-capitalize">
                        {{ emailDoc3.name.substring(0, 16) }}...
                      </p>
                    </a>
                    <v-icon v-if="emailDoc3" @click="clear(3)"
                      >mdi-close</v-icon
                    >
                  </span>
                </v-col>
              </v-row>

              <!-- </v-card> -->
            </v-col>

            <!-- document -->
          </v-card-text>
          <!-- actions -->
          <v-fade-transition>
            <v-card-actions
              class="sticky-v-card-actions bottom-action-booking"
              v-if="!editMode"
            >
              <v-switch
                class="mx-8"
                v-model="sendAdminEmail"
                label="Email Details to Booking?"
              ></v-switch>
              <v-switch
                class="mx-8"
                v-model="alertUserOfNewDashboardMessage"
                label="Alert User of New Dashboard Message?"
              ></v-switch>
              <v-spacer />
              <v-btn
                color="error"
                outlined
                class="mr-3"
                @click="notAvailableDialog = true"
                >Not Available</v-btn
              >

              <v-btn
                depressed
                color="primary"
                @click="onSubmit"
                :loading="loading"
                :disabled="loading"
                >Send Confirmation</v-btn
              >
            </v-card-actions>
          </v-fade-transition>
          <v-fade-transition>
            <v-card-actions
              v-if="editMode"
              class="sticky-v-card-actions bottom-action-booking"
            >
              <v-spacer />
              <v-btn
                depressed
                color="success"
                :loading="loading"
                :disabled="loading"
                @click="handleEditSubmit"
                >Save Edits</v-btn
              >
            </v-card-actions>
          </v-fade-transition>
        </v-card>
      </v-col>
    </v-row>

    <!-- notAvailableDialog -->
    <v-dialog v-model="notAvailableDialog" width="500">
      <v-card :loading="loading">
        <v-card-text class="pt-5 text--primary">
          <h2 class="headline mb-5">Inventory Selection Not Available</h2>
          <p class="body-1">
            If this inventory selection is unavailable, click the button below
            to
            <strong
              >notify the traveler and archive this booking request.</strong
            >
          </p>
          <p class="body-1">
            The traveler will then be allowed to select another inventory
            option, and will be sent the following message.
          </p>
          <p class="body-1">
            <strong>{{ inventoryUnavailableMessage }}</strong>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="notifyUnavailable"
            :loading="loading"
            :disabled="loading"
            >Notify Traveler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cancelDialog -->
    <v-dialog v-model="cancelDialog" width="550">
      <v-card :loading="loading">
        <v-card-text class="pt-5 text--primary body-1">
          <v-btn
            icon
            @click="cancelDialog = false"
            absolute
            top
            right
            :loading="loading"
            :disabled="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h2 class="headline mb-5 d-flex align-items-center">
            <v-icon left color="error">mdi-alert-outline</v-icon>
            Cancel This Booking?
          </h2>

          <p>Click below to cancel this booking.</p>
          <p><i>Cancelling this booking will:</i></p>
          <ul>
            <li>Archive this booking within the admin portal</li>
            <li>
              Grant the traveler access to select another inventory option
              within the traveler portal
            </li>
            <li>
              Send a message to the traveler within their traveler portal
              dashboard
            </li>
            <li>
              Send an email to the traveler alerting them of a new message
            </li>
            <li>Send an admin notification to the admin portal</li>
          </ul>

          <p class="mt-6">
            <strong> This action is permanent and cannot be undone.</strong>
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            depressed
            @click="onCancelBooking"
            :loading="loading"
            :disabled="loading"
            >Cancel Booking</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "@/plugins/firebase";
import formatDate from "@/mixins/formatDate";
import moment from "moment";
import formatPhone from "@/mixins/formatPhone";
import formRules from "@/mixins/formRules";
import copyText from "@/mixins/copyText";
import { portalsApp, mainApp } from "../../firebase";
const bookings = firebase.firestore().collection("bookingsConfirmations");
const userAccounts = firebase.firestore().collection("userAccounts");
export default {
  mixins: [formatDate, formRules, formatPhone, copyText],
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  data: () => ({
    emailDoc: "",
    emailDoc2: "",
    emailDoc3: "",
    cancelDialog: false,
    editMode: false,
    notAvailableDialog: false,
    booking: "",
    actualBooking: {
      actualCommissionsPaid: 0,
      actualPeakSeasonSurchargeAmount: 0,
      actualHighOccupancySurchargeAmount: 0,
      actualUpgradeSurcharge: 0,
      actualThirdYearSurcharge: 0,
      actualOtherFees: 0,
      actualExtrasCost: 0,
      actualRoomCost: 0,
      actualTaxesAndFees: 0,
      actualTotalCost: 0,
      actualProfitAndLost: 0,
      actualRepeatProfitAndLost: 0,
      actualPackageCost: 0,
    },
    userAccount: "",
    checkInDate: "",
    checkOutDate: "",
    showCheckInDatePicker: false,
    showCheckOutDatePicker: false,
    checkInDateTime: "",
    checkOutDateTime: "",
    resortPhoneNumber: "",
    surcharges: [],
    surchargesTotal: "",
    sendAdminEmail: true,
    alertUserOfNewDashboardMessage: true,
    inventoryUnavailableMessage:
      "Our apologies, but the inventory option you have selected was not available. Please click the button above to browse travel accommodations and make a new selection. If you have previously entered credit card information, no charges have/will be made. You will need to re-enter your credit card information again if applicable.",
  }),

  methods: {
    onFileSelected(event, doc) {
      const file = event.target.files[0]; // Get the selected file
      if (!file) return;

      // Accept images, PDFs, and DOCX files
      const acceptedTypes = [
        "application/pdf", // PDFs
        "image/png", // PNG images
        "image/jpeg", // JPEG/JPG images
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      ];

      if (!acceptedTypes.includes(file.type)) {
        alert("Only PDF, image, or DOCX files are allowed.");
        return;
      }

      // Generate a Blob URL for the file
      const fileBlob = new Blob([file], { type: file.type });
      const fileUrl = URL.createObjectURL(fileBlob);

      // Assign the file data to emailDoc and booking
      if (doc === 1) {
        this.emailDoc = file;
        this.booking.certificatePdf = { name: file.name, url: fileUrl };
      } else if (doc === 2) {
        this.emailDoc2 = file;
        this.booking.certificatePdf2 = { name: file.name, url: fileUrl };
      } else if (doc === 3) {
        this.emailDoc3 = file;
        this.booking.certificatePdf3 = { name: file.name, url: fileUrl };
      }
    },
    openCertificate(doc) {
      if (doc === 1) {
        if (!this.booking.certificatePdf) {
          return;
        }
        window.open(this.booking.certificatePdf.url, "_blank");
      } else if (doc === 2) {
        if (!this.booking.certificatePdf2) {
          return;
        }
        window.open(this.booking.certificatePdf2.url, "_blank");
      } else if (doc === 3) {
        if (!this.booking.certificatePdf3) {
          return;
        }
        window.open(this.booking.certificatePdf3.url, "_blank");
      }
    },
    clear(doc) {
      if (doc === 1) {
        this.emailDoc = "";
        this.booking.certificatePdf = "";
      } else if (doc === 2) {
        this.emailDoc2 = "";
        this.booking.certificatePdf2 = "";
      } else if (doc === 3) {
        this.emailDoc3 = "";
        this.booking.certificatePdf3 = "";
      } else {
        this.emailDoc = "";
        this.emailDoc2 = "";
        this.emailDoc3 = "";
        this.booking.certificatePdf = "";
        this.booking.certificatePdf2 = "";
        this.booking.certificatePdf3 = "";
      }
    },
    parseFloatWithTwoDecimals(numberString) {
      const parsedNumber = parseFloat(numberString);
      const roundedNumber = Number(parsedNumber.toFixed(2));

      return roundedNumber;
    },
    async onCancelBooking() {
      await this.$store.dispatch("setLoading", true);

      // archive booking
      await portalsApp
        .firestore()
        .collection("bookingsConfirmations")
        .doc(this.booking.id)
        .update({
          archived: true,
          isConfirmed: false,
        });

      // new message to user
      let timelineItem = {
        adminActionRequired: false,
        archived: false,
        dateCreated: new Date().toISOString().substring(0, 10),
        from: "admin",
        message:
          "We've cancelled your booking. You're now able to select another inventory option.",
        readByAdmin: true,
        repliedToByAdmin: true,
        senderActionRequired: true,
        title: "Your booking has been cancelled",
        to: this.userAccount.email,
        icon: "mdi-alert-circle-outline",
        color: "error",
      };

      // grant traveler portal access to user account
      // add timeline message
      await portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          canAccess: true,
          canBook: true,
          canBrowseInventory: true,
          canSendMessages: true,
          confirmationID: "",
          confirmationNumber: "",
          dateConfirmed: "",
          dateInventorySelected: "",
          hasSelectedInventory: false,
          isBooked: false,
          isConfirmed: false,
          newDashboardAVSheetNotification: false,
          newDashboardConfirmationNotification: false,
          pendingAVSheetCreation: false,
          pendingConfirmation: false,
          timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
        });

      // send new dashboard message email
      await axios.post("/api/new-dashboard-message", {
        email: this.userAccount.email,
        secondaryEmail: this.userAccount.secondaryEmail,
      });

      // add admin notification
      await portalsApp
        .firestore()
        .collection("adminNotifications")
        .add({
          archived: false,
          dateArchived: "",
          text: `Booking cancelled and message sent to ${this.userAccount.email}`,
          dateAdded: new Date().toISOString().substring(0, 10),
        });

      // clean up and toast
      // snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Booking Cancelled",
        timeout: 5000,
      });

      this.cancelDialog = false;
      await this.$store.dispatch("setLoading", false);
      this.$router.push("/bookings");
    },

    async handleEditSubmit() {
      // validate form
      if (!this.$refs.form.validate())
        return this.$toast.error("Please check form for errors");

      this.$store.state.loading = true;

      let mpsId = {
        lastName: this.booking.lastName,
        puid: this.booking.packageID,
      };

      let mpsRecords = firebase
        .firestore()
        .collection("mps")
        .where("puid", "==", mpsId.puid);
      mpsRecords = await mpsRecords.get();
      if (mpsRecords.docs.length == 1) {
        await firebase
          .firestore()
          .collection("mps")
          .doc(mpsRecords.docs[0].id)
          .update({
            pl:
              this.parseFloatWithTwoDecimals(
                this.actualBooking.actualProfitAndLost
              ) || 0,
            repeatPl:
              this.parseFloatWithTwoDecimals(
                this.actualBooking.actualRepeatProfitAndLost
              ) || 0,
            confirmationNumber: this.booking.confirmationNumber || "",

            expectedTotalCost:
              this.parseFloatWithTwoDecimals(this.booking.totalCost) || 0,

            // daysUntilTravel: differenceNights,
            expectedNetOutflow:
              this.parseFloatWithTwoDecimals(this.booking.netProfit) || 0,
            travelDate: moment(this.checkInDate, "YYYY-MM-DD").format(
              "MM-DD-YYYY"
            ),
            // dateBooked: this.booking.dateSelectedForBooking,
            destination: this.booking.destination,
            resort: this.booking.resortName,
            totalRoomCost:
              this.parseFloatWithTwoDecimals(
                this.actualBooking.actualTotalCost
              ) || 0,
            supplier: this.booking.source,
            upgrade: this.parseFloatWithTwoDecimals(
              this.parseFloatWithTwoDecimals(
                this.actualBooking.actualPeakSeasonSurchargeAmount
              ) +
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualHighOccupancySurchargeAmount
                ) +
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualUpgradeSurcharge
                ) +
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualThirdYearSurcharge
                ) +
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualOtherFees
                )
            ),
            toursAndInclusionsDue: this.parseFloatWithTwoDecimals(
              this.actualBooking.actualExtrasCost
            ),
            toursAndInclusionsTotalCost: this.parseFloatWithTwoDecimals(
              this.actualBooking.actualExtrasCost
            ),
          });
      }

      // updating userDoc
      await firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          secondaryEmail: this.userAccount.secondaryEmail,
        });

      // construct bookingDoc id
      let docID =
        this.userAccount.packageID +
        "-" +
        this.userAccount.numberOfInventorySelections;

      // update bookingDoc
      await firebase
        .firestore()
        .collection("bookingsConfirmations")
        .doc(docID)
        .update({
          firstName: this.booking.firstName,
          lastName: this.booking.lastName,
          lastName: this.booking.lastName,
          destination: this.booking.destination,
          resortName: this.booking.resortName,
          resortAddress: this.booking.resortAddress,
          checkInDate: this.checkInDate,
          checkOutDate: this.checkOutDate,
          checkInDateTime: this.booking.checkInDateTime,
          checkOutDateTime: this.booking.checkOutDateTime,
          resortPhoneNumber: this.booking.resortPhoneNumber,
          maxOccupancy: this.booking.maxOccupancy,
          maxOccupancyDetails: this.booking.maxOccupancyDetails,
          bedrooms: this.booking.bedrooms,
          source: this.booking.source,
          kitchen: this.booking.kitchen,
          numAdults: this.booking.numAdults,
          numChildren: this.booking.numChildren,

          commissionsPaid:
            this.parseFloatWithTwoDecimals(this.booking.commissionsPaid) || 0,
          costToOrg:
            this.parseFloatWithTwoDecimals(this.booking.costToOrg) || 0,

          highOccupancySurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.booking.highOccupancySurchargeAmount
            ) || 0,

          netProfit:
            this.parseFloatWithTwoDecimals(this.booking.netProfit) || 0,
          peakSeasonSurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.booking.peakSeasonSurchargeAmount
            ) || 0,
          roomCost: this.parseFloatWithTwoDecimals(this.booking.roomCost) || 0,
          taxesAndFees:
            this.parseFloatWithTwoDecimals(this.booking.taxesAndFees) || 0,
          totalCost:
            this.parseFloatWithTwoDecimals(this.booking.totalCost) || 0,
          totalIncome:
            this.parseFloatWithTwoDecimals(this.booking.totalIncome) || 0,
          totalSurcharges:
            this.parseFloatWithTwoDecimals(this.booking.totalSurcharges) || 0,
          toursTickets:
            this.parseFloatWithTwoDecimals(this.booking.toursTickets) || 0,
          thirdYearSurcharge:
            this.parseFloatWithTwoDecimals(this.booking.thirdYearSurcharge) ||
            0,

          adminNotes: this.booking.adminNotes
            ? this.booking.adminNotes.trim()
            : "",

          travelerNotes: this.booking.adminNotes
            ? this.booking.travelerNotes.trim()
            : "",

          confirmationNumber: this.booking.confirmationNumber,

          actualCommissionsPaid:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualCommissionsPaid
            ) || 0,
          actualPeakSeasonSurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualPeakSeasonSurchargeAmount
            ) || 0,
          actualHighOccupancySurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualHighOccupancySurchargeAmount
            ) || 0,
          actualUpgradeSurcharge:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualUpgradeSurcharge
            ) || 0,
          actualThirdYearSurcharge:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualThirdYearSurcharge
            ) || 0,
          actualOtherFees:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualOtherFees
            ) || 0,
          actualExtrasCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualExtrasCost
            ) || 0,
          actualRoomCost:
            this.parseFloatWithTwoDecimals(this.actualBooking.actualRoomCost) ||
            0,
          actualTaxesAndFees:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualTaxesAndFees
            ) || 0,
          actualTotalCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualTotalCost
            ) || 0,
          actualProfitAndLost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualProfitAndLost
            ) || 0,
          actualRepeatProfitAndLost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualRepeatProfitAndLost
            ) || 0,
          actualPackageCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualPackageCost
            ) || 0,
        });

      this.$store.state.loading = false;
      this.editMode = false;
      this.$toast.info("Don't forget to send confirmation if needed");
      this.$toast.success("Confirmation edited successfully, but NOT sent");
    },
    calcValues() {
      this.inputCalc();

      // EXPECTED
      if (this.booking.peakSeasonSurchargeAmount == "")
        this.booking.peakSeasonSurchargeAmount = "0";
      if (this.booking.highOccupancySurchargeAmount == "")
        this.booking.highOccupancySurchargeAmount = "0";
      if (this.booking.totalSurcharges == "")
        this.booking.totalSurcharges = "0";
      if (this.booking.toursTickets == "") this.booking.toursTickets = "0";
      if (this.booking.costToOrg == "") this.booking.costToOrg = "0";
      if (this.booking.totalIncome == "") this.booking.totalIncome = "0";
      if (this.booking.roomCost == "") this.booking.roomCost = "0";
      if (this.booking.taxesAndFees == "") this.booking.taxesAndFees = "0";
      if (this.booking.commissionsPaid == "")
        this.booking.commissionsPaid = "0";
      if (this.booking.totalCost == "") this.booking.totalCost = "0";
      if (this.booking.netProfit == "") this.booking.netProfit = "0";
      if (!this.booking.thirdYearSurcharge)
        this.booking.thirdYearSurcharge = "0";

      this.booking.totalSurcharges = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.booking.peakSeasonSurchargeAmount) +
          this.parseFloatWithTwoDecimals(this.booking.thirdYearSurcharge) +
          this.parseFloatWithTwoDecimals(
            this.booking.highOccupancySurchargeAmount
          )
      );
      this.booking.totalIncome = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.booking.toursTickets) +
          this.parseFloatWithTwoDecimals(this.booking.totalSurcharges) +
          this.parseFloatWithTwoDecimals(this.booking.costToOrg)
      );
      this.booking.totalCost = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.booking.roomCost) +
          this.parseFloatWithTwoDecimals(this.booking.taxesAndFees) +
          this.parseFloatWithTwoDecimals(this.booking.commissionsPaid)
      );
      this.booking.netProfit = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.booking.totalIncome) -
          this.parseFloatWithTwoDecimals(this.booking.totalCost)
      );

      // ACTUAL

      this.actualBooking.actualTotalCost = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.actualBooking.actualTaxesAndFees) +
          this.parseFloatWithTwoDecimals(this.actualBooking.actualRoomCost)
      );

      this.actualBooking.actualProfitAndLost = this.parseFloatWithTwoDecimals(
        this.parseFloatWithTwoDecimals(this.actualBooking.actualPackageCost) -
          this.parseFloatWithTwoDecimals(
            this.actualBooking.actualCommissionsPaid
          ) -
          (this.parseFloatWithTwoDecimals(this.actualBooking.actualExtrasCost) +
            this.parseFloatWithTwoDecimals(this.actualBooking.actualRoomCost) +
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualTaxesAndFees
            ) +
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualOtherFees
            )) +
          this.parseFloatWithTwoDecimals(
            this.actualBooking.actualPeakSeasonSurchargeAmount
          ) +
          this.parseFloatWithTwoDecimals(
            this.actualBooking.actualHighOccupancySurchargeAmount
          ) +
          this.parseFloatWithTwoDecimals(
            this.actualBooking.actualUpgradeSurcharge
          ) +
          this.parseFloatWithTwoDecimals(
            this.actualBooking.actualThirdYearSurcharge
          )
      );
    },
    checkActualFieldsValue() {
      // ACTUAL
      this.actualBooking.actualCommissionsPaid =
        this.booking.actualCommissionsPaid || 0;
      this.actualBooking.actualPeakSeasonSurchargeAmount =
        this.booking.actualPeakSeasonSurchargeAmount || 0;
      this.actualBooking.actualHighOccupancySurchargeAmount =
        this.booking.actualHighOccupancySurchargeAmount || 0;
      this.actualBooking.actualUpgradeSurcharge =
        this.booking.actualUpgradeSurcharge || 0;
      this.actualBooking.actualThirdYearSurcharge =
        this.booking.actualThirdYearSurcharge || 0;
      this.actualBooking.actualOtherFees = this.booking.actualOtherFees || 0;
      this.actualBooking.actualExtrasCost = this.booking.actualExtrasCost || 0;
      this.actualBooking.actualRoomCost = this.booking.actualRoomCost || 0;
      this.actualBooking.actualTaxesAndFees =
        this.booking.actualTaxesAndFees || 0;
      this.actualBooking.actualTotalCost = this.booking.actualTotalCost || 0;
      this.actualBooking.actualProfitAndLost =
        this.booking.actualProfitAndLost || 0;
      this.actualBooking.actualRepeatProfitAndLost =
        this.booking.actualRepeatProfitAndLost || 0;
      this.actualBooking.actualPackageCost =
        this.booking.actualPackageCost || this.booking.costToOrg || 0;
    },
    async notifyUnavailable() {
      await this.$store.dispatch("setLoading", true);

      // send new dashboard message email
      await axios.post("/api/new-dashboard-message", {
        email: this.userAccount.email,
        secondaryEmail: this.userAccount.secondaryEmail,
      });

      // mark booking/inventory selection unavailable && archive
      await portalsApp
        .firestore()
        .collection("bookingsConfirmations")
        .doc(this.booking.id)
        .update({
          selectedInventoryUnavailable: true,
          archived: true,
        });

      // timline item for user dashboard
      let timelineItem = {
        adminActionRequired: false,
        archived: false,
        dateCreated: new Date().toISOString().substring(0, 10),
        from: "admin",
        message: this.inventoryUnavailableMessage,
        readByAdmin: true,
        repliedToByAdmin: true,
        senderActionRequired: true,
        title: "Inventory Selection Unavailable",
        to: this.userAccount.email,
        icon: "mdi-alert-circle-outline",
        color: "error",
      };

      // update user account
      await firebase
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id)
        .update({
          canBook: true,
          canBrowseInventory: true,
          newDashboardAVSheetNotification: false,
          pendingAVSheetCreation: false,
          pendingConfirmation: false,
          timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
        });

      // send new dashboard message email
      await axios.post("/api/new-dashboard-message", {
        email: this.userAccount.email,
        secondaryEmail: this.userAccount.secondaryEmail,
      });

      // add admin notification
      await portalsApp
        .firestore()
        .collection("adminNotifications")
        .add({
          archived: false,
          dateArchived: "",
          text: `Booking inventory selection marked unavailable and message sent to ${this.userAccount.email}`,
          dateAdded: new Date().toISOString().substring(0, 10),
        });

      // snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Traveler notified",
        timeout: 5000,
      });

      await this.$store.dispatch("setLoading", false);
      this.$router.push("/bookings");
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", true);

        // constructing data
        this.booking.checkInDate = this.checkInDate;
        this.booking.checkOutDate = this.checkOutDate;
        this.booking.checkInDateTime = this.booking.checkInDateTime;
        this.booking.checkOutDateTime = this.booking.checkOutDateTime;
        this.booking.resortPhoneNumber = this.booking.resortPhoneNumber;
        // this.booking.surcharges = this.surcharges;
        // this.booking.surchargesTotal = this.surchargesTotal;

        if (!this.booking.adminNotes) this.booking.adminNotes = "";
        if (!this.booking.travelerNotes) this.booking.travelerNotes = "";

        let checkInDocument = {};
        if (this.emailDoc && !this.emailDoc.hasOwnProperty("url")) {
          let pdf = this.emailDoc;
          const pdfRef = mainApp
            .storage()
            .ref(
              `bookingsComfirmationDocuments/${pdf.name
                .trim()
                .replaceAll(" ", "-")}`
            );
          const pdfUploaded = await pdfRef.put(pdf);
          checkInDocument.url = await pdfUploaded.ref.getDownloadURL();
          checkInDocument.name = pdf.name.trim().replaceAll(" ", "-");
          this.booking.certificatePdf = checkInDocument;
          delete pdf.file;
        } else {
          checkInDocument = this.emailDoc;
        }

        let checkInDocument2 = {};
        if (this.emailDoc2 && !this.emailDoc2.hasOwnProperty("url")) {
          let pdf = this.emailDoc2;
          const pdfRef = mainApp
            .storage()
            .ref(
              `bookingsComfirmationDocuments/${pdf.name
                .trim()
                .replaceAll(" ", "-")}`
            );
          const pdfUploaded = await pdfRef.put(pdf);
          checkInDocument2.url = await pdfUploaded.ref.getDownloadURL();
          checkInDocument2.name = pdf.name.trim().replaceAll(" ", "-");
          this.booking.certificatePdf2 = checkInDocument2;
          delete pdf.file;
        } else {
          checkInDocument2 = this.emailDoc2;
        }

        let checkInDocument3 = {};
        if (this.emailDoc3 && !this.emailDoc3.hasOwnProperty("url")) {
          let pdf = this.emailDoc3;
          const pdfRef = mainApp
            .storage()
            .ref(
              `bookingsComfirmationDocuments/${pdf.name
                .trim()
                .replaceAll(" ", "-")}`
            );
          const pdfUploaded = await pdfRef.put(pdf);
          checkInDocument3.url = await pdfUploaded.ref.getDownloadURL();
          checkInDocument3.name = pdf.name.trim().replaceAll(" ", "-");
          this.booking.certificatePdf3 = checkInDocument3;
          delete pdf.file;
        } else {
          checkInDocument3 = this.emailDoc3;
        }

        // update bookingDoc
        let docID =
          this.userAccount.packageID +
          "-" +
          this.userAccount.numberOfInventorySelections;

        let bookingDocRef = firebase
          .firestore()
          .collection("bookingsConfirmations")
          .doc(docID);

        await bookingDocRef.update({
          isConfirmed: true,
          dateConfirmed: new Date().toISOString().substring(0, 10),
          certificatePdf: this.booking.certificatePdf || "",
          certificatePdf2: this.booking.certificatePdf2 || "",
          certificatePdf3: this.booking.certificatePdf3 || "",
          totalSurcharges: this.booking.totalSurcharges,
          thirdYearSurcharge: this.booking.thirdYearSurcharge || 0,
          netProfit: this.booking.netProfit,
          totalIncome: this.booking.totalIncome,
          maxOccupancy: this.booking.maxOccupancy,
          maxOccupancyDetails: this.booking.maxOccupancyDetails,
          bedrooms: this.booking.bedrooms,
          adminNotes: this.booking.adminNotes,
          travelerNotes: this.booking.travelerNotes,
          confirmationNumber: this.booking.confirmationNumber,
          firstName: this.booking.firstName,
          lastName: this.booking.lastName,
          checkInDateTime: this.booking.checkInDateTime,
          checkOutDateTime: this.booking.checkOutDateTime,
          resortPhoneNumber: this.booking.resortPhoneNumber,

          actualCommissionsPaid:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualCommissionsPaid
            ) || 0,
          actualPeakSeasonSurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualPeakSeasonSurchargeAmount
            ) || 0,
          actualHighOccupancySurchargeAmount:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualHighOccupancySurchargeAmount
            ) || 0,
          actualUpgradeSurcharge:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualUpgradeSurcharge
            ) || 0,
          actualThirdYearSurcharge:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualThirdYearSurcharge
            ) || 0,
          actualOtherFees:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualOtherFees
            ) || 0,
          actualExtrasCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualExtrasCost
            ) || 0,
          actualRoomCost:
            this.parseFloatWithTwoDecimals(this.actualBooking.actualRoomCost) ||
            0,
          actualTaxesAndFees:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualTaxesAndFees
            ) || 0,
          actualTotalCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualTotalCost
            ) || 0,
          actualProfitAndLost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualProfitAndLost
            ) || 0,
          actualRepeatProfitAndLost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualRepeatProfitAndLost
            ) || 0,
          actualPackageCost:
            this.parseFloatWithTwoDecimals(
              this.actualBooking.actualPackageCost
            ) || 0,
        });

        // update userAccount
        let userAccountDocRef = firebase
          .firestore()
          .collection("userAccounts")
          .doc(this.booking.userID);

        // timeline item
        let timelineItem = {
          adminActionRequired: false,
          archived: false,
          dateCreated: new Date().toISOString().substring(0, 10),
          from: "admin",
          message:
            "We've booked your travel. It is now safe to purchase transportation, airfare, etc. Your reservation will appear in your resort's booking system no earlier than 14 days prior to your arrival.",
          readByAdmin: true,
          repliedToByAdmin: false,
          senderActionRequired: false,
          title: "Your booking is confirmed",
          to: "",
          icon: "mdi-map-marker-check-outline",
          color: "success",
        };

        // update userAccount doc
        await userAccountDocRef.update({
          canBook: false,
          canBrowseInventory: false,
          isBooked: true,
          newDashboardConfirmationNotification: true,
          isConfirmed: true,
          dateConfirmed: new Date().toISOString().substring(0, 10),
          timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
          pendingAVSheetCreation: false,
          pendingConfirmation: false,
          confirmationNumber: this.booking.confirmationNumber,
          confirmationID: this.booking.id,
        });

        if (checkInDocument) {
          await axios.post("/api/send-certificate", {
            userAccount: this.userAccount,
            checkInDocument,
            checkInDocument2,
            checkInDocument3,
          });
        }

        if (this.sendAdminEmail) {
          await axios.post("/api/new-confirmation", {
            confirmation: this.booking,
            userAccount: this.userAccount,
          });
        }

        // alert user of new dashboard message
        if (this.alertUserOfNewDashboardMessage) {
          await axios.post("/api/new-dashboard-message", {
            email: this.userAccount.email,
            secondaryEmail: this.userAccount.secondaryEmail,
          });
        }

        // add admin notification
        await portalsApp
          .firestore()
          .collection("adminNotifications")
          .add({
            archived: false,
            dateArchived: "",
            text: `Confirmation sent to ${this.userAccount.email}`,
            dateAdded: new Date().toISOString().substring(0, 10),
          });

        let mpsId = {
          lastName: this.booking.lastName,
          puid: this.booking.packageID,
        };

        let mpsRecords = firebase
          .firestore()
          .collection("mps")
          .where("puid", "==", mpsId.puid);
        mpsRecords = await mpsRecords.get();
        if (mpsRecords.docs.length == 1) {
          await firebase
            .firestore()
            .collection("mps")
            .doc(mpsRecords.docs[0].id)
            .update({
              pl:
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualProfitAndLost
                ) || 0,
              repeatPl:
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualRepeatProfitAndLost
                ) || 0,
              confirmationNumber:
                this.parseFloatWithTwoDecimals(
                  this.booking.confirmationNumber
                ) || "",
              expectedTotalCost:
                this.parseFloatWithTwoDecimals(this.booking.totalCost) || 0,

              // daysUntilTravel: differenceNights,
              expectedNetOutflow:
                this.parseFloatWithTwoDecimals(this.booking.netProfit) || 0,
              travelDate: moment(this.checkInDate, "YYYY-MM-DD").format(
                "MM-DD-YYYY"
              ),
              dateBooked: moment().format("YYYY-MM-DD"),
              destination: this.booking.destination,
              resort: this.booking.resortName,
              totalRoomCost:
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualTotalCost
                ) || 0,
              supplier: this.booking.source,
              upgrade: this.parseFloatWithTwoDecimals(
                this.parseFloatWithTwoDecimals(
                  this.actualBooking.actualPeakSeasonSurchargeAmount
                ) +
                  this.parseFloatWithTwoDecimals(
                    this.actualBooking.actualHighOccupancySurchargeAmount
                  ) +
                  this.parseFloatWithTwoDecimals(
                    this.actualBooking.actualUpgradeSurcharge
                  ) +
                  this.parseFloatWithTwoDecimals(
                    this.actualBooking.actualThirdYearSurcharge
                  ) +
                  this.parseFloatWithTwoDecimals(
                    this.actualBooking.actualOtherFees
                  )
              ),
              toursAndInclusionsDue: this.parseFloatWithTwoDecimals(
                this.actualBooking.actualExtrasCost
              ),
              toursAndInclusionsTotalCost: this.parseFloatWithTwoDecimals(
                this.actualBooking.actualExtrasCost
              ),
            });
        }

        // snackbar
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Confirmation Sent",
          timeout: 5000,
        });
        await this.$store.dispatch("setLoading", false);

        // route
        this.$router.push("/bookings");

        return;
      }
      // error snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "error",
        text: "Please check the form for errors",
        timeout: 5000,
      });
      return;
    },
    inputCalc() {
      const regex = /^\d*\.?\d{0,2}$/; // Regular expression to match up to two decimal places
      let array = [
        "peakSeasonSurchargeAmount",
        "highOccupancySurchargeAmount",
        "totalSurcharges",
        "toursTickets",
        "costToOrg",
        "totalIncome",
        "roomCost",
        "taxesAndFees",
        "commissionsPaid",
        "totalCost",
        "netProfit",
        "thirdYearSurcharge",
      ];

      array.map((el) => {
        if (this.booking.hasOwnProperty(el)) {
          if (!regex.test(this.booking[el]) && this.booking[el] !== "") {
            this.booking[el] = `${this.booking[el]}`.slice(0, -1) * 1;
          }
        }
      });

      let obj = this.actualBooking;
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (!regex.test(obj[key]) && obj[key] !== "") {
            this.actualBooking[key] =
              `${this.actualBooking[key]}`.slice(0, -1) * 1; // Remove the last character if it doesn't match the pattern
          }
        }
      }
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      async handler(id) {
        // bind booking
        await this.$bind("booking", bookings.doc(id));

        // set dates
        this.checkInDate = new Date(this.booking.checkInDate)
          .toISOString()
          .substring(0, 10);
        this.checkOutDate = new Date(this.booking.checkOutDate)
          .toISOString()
          .substring(0, 10);

        this.checkInDateTime = this.booking.checkInDateTime || "";
        this.checkOutDateTime = this.booking.checkOutDateTime || "";
        this.resortPhoneNumber = this.booking.resortPhoneNumber || "";

        // if no toursTickets > set to "0"
        if (!this.booking.toursTickets) this.booking.toursTickets = "0";

        // bind userAccount
        this.booking.certificatePdf = this.booking.certificatePdf || "";
        this.booking.certificatePdf2 = this.booking.certificatePdf2 || "";
        this.booking.certificatePdf3 = this.booking.certificatePdf3 || "";
        this.emailDoc = this.booking.certificatePdf || false;
        this.emailDoc2 = this.booking.certificatePdf2 || false;
        this.emailDoc3 = this.booking.certificatePdf3 || false;

        this.checkActualFieldsValue();
        await this.$bind("userAccount", userAccounts.doc(this.booking.userID));
      },
    },
  },
};
</script>

<style>
#sendConfirmation .v-list-item {
  min-height: 24px !important;
}

.form__group {
  position: relative;
  z-index: 9;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  outline: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  padding: 4px 0 2px;
  background: transparent;
  transition: border-color 0.2s;
}
.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
  cursor: text;
  top: 2px;
  transform: translateY(0px) scale(1);
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
  transform: translateY(-18px) scale(0.75);
  transform-origin: top left;
  z-index: -1;
}

.form__field:focus {
  border-width: 2px;
  border-color: #1976d2;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  color: #1976d2;
  transform: translateY(-18px) scale(0.75);
  transform-origin: top left;
}

/* / reset input / */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.bottom-action-booking {
  z-index: 9;
}

.event-border-dash input {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.42) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.event-border-dash label {
  color: rgba(0, 0, 0, 0.38) !important;
}
.document-certificate {
  display: flex;
}
.openDocLink:hover {
  text-decoration: underline;
}
.ql-container {
  height: 100px;
  max-height: 200px;
  overflow-y: auto;;
}
.ql-editor {
  height: 100%;
}
</style>